import {
  Dialog as RCADialog,
  DialogProps as RCADialogProps,
  Heading
} from 'react-aria-components'
import { Button } from '@/src/stories/Button'
import { tv } from 'tailwind-variants'
import { forwardRef, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'
import { X } from '@untitled-ui/icons-react'

interface DialogProps extends RCADialogProps {
  title?: string
  description?: string
  buttonCloseLabel?: string
  buttonActionLabel?: string
  customActionButton?: ReactNode
  onAction?: () => void
  onCloseWithBtn?: () => void
  icon?: ReactNode
  restrictWidth?: boolean
  noPadding?: boolean
  actionButtonDisabled?: boolean
  submitButton?: boolean
  drawerStyled?: boolean
}

const dialogStyles = tv({
  base: 'p-6 bg-neutral-0 rounded shadow space-y-10 outline-none focus:outline-none',
  variants: {
    restrictWidth: {
      true: 'max-w-[400px]'
    },
    noPadding: {
      true: 'p-0'
    },
    drawerStyled: {
      true: 'p-0'
    }
  }
})

const iconStyles = tv({
  base: 'w-12 shrink-0 h-12 flex rounded-full items-center justify-center',
  variants: {
    isAlert: {
      true: 'bg-critical-100 text-critical-500',
      false: 'bg-brand-100 text-brand-500'
    },
    isSquare: {
      true: 'bg-brand-500 text-brand-100 rounded-xl'
    },
    isSquareAndAlert: {
      true: 'bg-critical-500 text-critical-100'
    }
  }
})

const headingStyles = tv({
  base: "text-neutral-900 text-left text-[21px] font-bold font-['Plus Jakarta Sans'] break-normal"
})

const Dialog = forwardRef<HTMLDivElement, DialogProps>(
  (
    {
      title,
      description,
      buttonCloseLabel,
      buttonActionLabel,
      children,
      onCloseWithBtn,
      icon,
      restrictWidth = true,
      noPadding = false,
      drawerStyled = false,
      ...props
    },
    ref
  ) => {
    return (
      <RCADialog
        {...props}
        className={twMerge(
          dialogStyles({
            restrictWidth,
            noPadding,
            drawerStyled
          }),
          props.className
        )}>
        {({ close }) => (
          <div ref={ref}>
            <div
              ref={ref}
              className={
                'flex justify-start self-stretch max-h-[calc(100vh-2rem)] items-start flex-col'
              }>
              <div
                className={`flex flex-row justify-start items-center gap-4 w-full ${
                  drawerStyled && 'p-6 border-b border-neutral-200'
                }`}>
                {icon && (
                  <span
                    className={iconStyles({
                      isAlert: props.role === 'alertdialog',
                      isSquare: drawerStyled,
                      isSquareAndAlert:
                        drawerStyled && props.role === 'alertdialog'
                    })}>
                    {icon}
                  </span>
                )}
                <div className={'flex flex-col justify-start items-start'}>
                  {!!title && (
                    <Heading className={headingStyles()} slot='title'>
                      {title}
                    </Heading>
                  )}
                  {!!description && (
                    <p
                      className={
                        'text-neutral-600 text-sm font-normal text-left'
                      }>
                      {description}
                    </p>
                  )}
                </div>

                {buttonCloseLabel && drawerStyled && (
                  <div
                    className={'grow flex flex-row justify-end items-center'}>
                    <X
                      width={20}
                      className={'cursor-pointer'}
                      onClick={() => {
                        ;(!onCloseWithBtn ? close : onCloseWithBtn)()
                      }}
                    />
                  </div>
                )}
              </div>
              <div
                className={
                  'flex-1 h-full w-full overflow-y-auto scrollbar-hide'
                }>
                <>{children}</>
              </div>

              {(buttonCloseLabel || !!props.onAction) && (
                <span
                  className={`w-full flex items-center pt-6 gap-4 justify-end ${
                    drawerStyled && 'p-6'
                  }`}>
                  {buttonCloseLabel && !drawerStyled && (
                    <Button
                      hierarchy={'secondary'}
                      label={buttonCloseLabel}
                      onPress={() => {
                        ;(!onCloseWithBtn ? close : onCloseWithBtn)()
                      }}
                    />
                  )}
                  {!props.customActionButton &&
                    (!!props.onAction || props.submitButton) && (
                      <Button
                        isDisabled={props.actionButtonDisabled}
                        type={props.submitButton ? 'submit' : 'button'}
                        hierarchy={'primary'}
                        destructive={props.role === 'alertdialog'}
                        label={buttonActionLabel}
                        onPress={props.onAction}
                      />
                    )}
                  {props.customActionButton}
                </span>
              )}
            </div>
          </div>
        )}
      </RCADialog>
    )
  }
)

Dialog.displayName = 'Dialog'

export default Dialog
