import * as React from 'react'
import { ReactElement, SVGProps } from 'react'
import AssistChip from '@/src/stories/chips/AssistChip'
import { twMerge } from 'tailwind-merge'

interface IconWrapperProps
  extends Omit<React.SVGProps<SVGSVGElement>, 'ref'>,
    React.RefAttributes<SVGSVGElement> {
  /**
   * icon component
   */
  Icon: (props: SVGProps<SVGSVGElement>) => ReactElement
  /**
   * icon size (will set both width and height)
   */
  size?: string | number
  /**
   * icon color
   */
  color?: string

  bgColor?: string
  type?: 'default' | 'chip' | 'colored-circle'
}

/**
 * Generic wrapper to be used anywhere you need an icon
 */
export const IconWrapper = ({
  Icon,
  size = 24,
  color,
  type = 'default',
  className,
  bgColor,
  ...props
}: IconWrapperProps) => (
  <div>
    {type === 'chip' ? (
      // TODO: fix any
      <AssistChip squared onPress={props.onClick as any} className={className}>
        <Icon width={size} height={size} color={color} />
      </AssistChip>
    ) : type === 'colored-circle' ? (
      <span
        className={twMerge(
          'mix-blend-multiply text-brand-600 rounded-full p-3 flex items-center justify-center',
          bgColor ? bgColor : 'bg-brand-100'
        )}>
        <Icon width={size} height={size} color={color} {...props} />
      </span>
    ) : (
      <Icon width={size} height={size} color={color} {...props} />
    )}
  </div>
)
