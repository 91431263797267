import { createStore, useStore } from 'zustand'
import { createContext, useContext, useEffect, useRef } from 'react'
import { DefinedPartial, Organization, ProfileWithJoins } from '@/types'
import {
  getOrgRole,
  getTeamsIds,
  hasTeamFullViewAccess,
  isManagerOfTeam
} from '@/utils/roles'
import { Enums } from '@/db_types'

type State = {
  id?: number
  organizations: Organization[]
  setOrganizationId: (id: number) => void
  selectedOrganization?: Organization
  setSelectedOrganization?: (organization: Organization) => void

  profile?: ProfileWithJoins
  setProfile: (profile: ProfileWithJoins) => void
  getRole: () => Enums<'org_role'> | undefined
  hasFullView: (teamId?: number) => boolean
  isManager: (teamId?: number) => boolean
  getTeamsIds: () => number[]
}

export const createOrgStore = (initProps?: Partial<State>) => {
  return createStore<State>()((set, get) => ({
    id: undefined,
    organizations: [],

    selectedOrganization: undefined,

    setOrganizationId: (id: number) => set({ id }),
    setSelectedOrganization: (organization: Organization) =>
      set({ selectedOrganization: organization }),

    profile: undefined,
    setProfile: (profile: ProfileWithJoins) => set({ profile }),

    getRole: () => getOrgRole(get().profile!, get().selectedOrganization!.id),

    hasFullView: teamId =>
      hasTeamFullViewAccess(
        get().profile!,
        get().selectedOrganization!.id,
        teamId
      ),

    isManager: teamId =>
      isManagerOfTeam(get().profile!, get().selectedOrganization!.id, teamId),

    getTeamsIds: () =>
      getTeamsIds(get().profile!, get().selectedOrganization!.id),

    ...initProps
  }))
}

const OrgStoreContext = createContext<State | null>(null)

type OrgProviderProps = React.PropsWithChildren<DefinedPartial<State>>

export function OrgProvider({ children, ...props }: OrgProviderProps) {
  const storeRef = useRef<State>()
  if (storeRef && !storeRef.current) {
    ;(storeRef.current as any) = createOrgStore(props)
  }
  return (
    <OrgStoreContext.Provider value={storeRef.current ?? null}>
      {children}
    </OrgStoreContext.Provider>
  )
}

export function useOrgContext<T>(
  selector: (state: State) => T,
  equalityFn?: (left: T, right: T) => boolean
): T {
  const store = useContext(OrgStoreContext)
  if (!store) throw new Error('Missing OrgStoreContext.Provider in the tree')

  // TODO - fix this
  // @ts-ignore
  return useStore(store, selector, equalityFn)
}

const themes: Record<string, { chartColors: string[] }> = {
  18: {
    chartColors: [
      'hsl(347, 77%, 50%)',
      'hsl(352, 83%, 91%)',
      'hsl(350, 80%, 72%)',
      'hsl(351, 83%, 82%)',
      'hsl(349, 77%, 62%)'
    ]
  },
  default: {
    chartColors: [
      'hsl(173, 58%, 39%)',
      'hsl(197, 37%, 24%)',
      'hsl(27, 87%, 67%)',
      'hsl(12, 76%, 61%)',
      'hsl(43, 74%, 66%)'
    ]
  }
}

export const useOrgTheme = (organizationId: number) => {
  useEffect(() => {
    const theme = themes[organizationId] || themes['default']
    theme.chartColors.forEach((color, index) => {
      document.documentElement.style.setProperty(
        `--chart-color-${index + 1}`,
        color
      )
    })

    // reset colors when unmounting
    return () => {
      themes['default'].chartColors.forEach((color, index) => {
        document.documentElement.style.setProperty(
          `--chart-color-${index + 1}`,
          color
        )
      })
    }
  }, [organizationId])
}
