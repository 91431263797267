import { tv } from 'tailwind-variants'
import { PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'

export enum BadgeSizes {
  s = 'small',
  m = 'medium',
  l = 'large'
}

export type BadgeColors =
  | 'neutral'
  | 'brand'
  | 'success'
  | 'warning'
  | 'critical'
  | 'orange'
  | 'yellow'
  | 'lime'
  | 'green'
  | 'teal'
  | 'cyan'
  | 'sky'
  | 'blue'
  | 'indigo'
  | 'violet'
  | 'purple'
  | 'fuchsia'
  | 'pink'
  | 'rose'
  | 'highlight'

const deterministicGetRandomColorFromText = (text: string) => {
  if (!text) return 'neutral'
  const textString = String(text)
  const colors: BadgeColors[] = [
    'blue',
    'green',
    'orange',
    'purple',
    'teal',
    'yellow',
    'teal',
    'sky',
    'orange',
    'teal',
    'violet',
    'pink',
    'indigo',
    'cyan',
    'lime',
    'sky',
    'fuchsia',
    'highlight'
  ]
  const charCodeSum = textString
    .split('')
    .reduce((acc, char) => acc + char.charCodeAt(0), 0)
  return colors[charCodeSum % colors.length]
}

export interface BadgeProps {
  size: BadgeSizes
  color?: BadgeColors
  iconLeading?: React.ReactNode
  iconTrailing?: React.ReactNode
  specificTextForColoringCalculation?: string
  className?: string
  useColorsFromClasses?: boolean
}

const styles = tv({
  base: 'inline-flex items-center justify-center rounded-full font-medium px-2 truncate ... mix-blend-normal',
  variants: {
    size: {
      small: 'text-xs h-5',
      medium: 'text-sm h-6',
      large: 'text-base h-8'
    },
    color: {
      neutral: 'bg-neutral-100 text-neutral-700',
      brand: 'bg-brand-100 text-brand-700',
      success: 'bg-success-100 text-success-700',
      warning: 'bg-warning-100 text-warning-700',
      critical: 'bg-critical-100 text-critical-700',
      orange: 'bg-orange-100 text-orange-700',
      yellow: 'bg-yellow-100 text-yellow-700',
      lime: 'bg-lime-100 text-lime-700',
      green: 'bg-green-100 text-green-700',
      teal: 'bg-teal-100 text-teal-700',
      cyan: 'bg-cyan-100 text-cyan-700',
      sky: 'bg-sky-100 text-sky-700',
      blue: 'bg-blue-100 text-blue-700',
      indigo: 'bg-indigo-100 text-indigo-700',
      violet: 'bg-violet-100 text-violet-700',
      purple: 'bg-purple-100 text-purple-700',
      fuchsia: 'bg-fuchsia-100 text-fuchsia-700',
      pink: 'bg-pink-100 text-pink-700',
      highlight: 'bg-highlight-100 text-highlight-700',
      rose: 'bg-rose-100 text-rose-700'
    }
  }
})

const Badge = ({
  size,
  color,
  children,
  iconLeading,
  iconTrailing,
  className,
  useColorsFromClasses
}: PropsWithChildren<BadgeProps>) => {
  return (
    <div
      className={twMerge(
        styles({
          size,
          color: useColorsFromClasses
            ? undefined
            : color ??
              deterministicGetRandomColorFromText(
                (children as string | undefined) ?? ''
              )
        }),
        className
      )}>
      {iconLeading && <span className='mr-1'>{iconLeading}</span>}
      {children}
      {iconTrailing && <span className='ml-1'>{iconTrailing}</span>}
    </div>
  )
}

export default Badge
