import { t, Trans } from '@lingui/macro'
import Link from 'next/link'
import { SidebarLink } from './constants'
import Avatar from '@/src/ui/avatar'
import { Sizes } from '@/src/ui/avatar/constants'
import { zIndexes } from '@/src/constants/z-index'
import { useRouter } from 'next/router'
import DolfinLogo from '@/svgs/dolfin-logo'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid'
import { UserGroupIcon } from '@heroicons/react/24/outline'
import { useOrgContext } from '@/hooks/state/organization'
import React, { useCallback, useState } from 'react'
import { TABS } from '@/types'
import DolfinIcon from '@/svgs/dolfin-icon'
import {
  Activity,
  AnnotationCheck,
  BarChartSquare02,
  Data,
  Dataflow01,
  FaceSmile,
  File05,
  HeartHand,
  Link03,
  ReceiptCheck,
  Rocket01,
  Rows03,
  Ruler,
  Settings02,
  Sliders02,
  Target01,
  Target04,
  Trophy01,
  User01,
  Users01
} from '@untitled-ui/icons-react'
import SidebarItem from '@/src/components/sidebar/sidebarItem'
import useSupabaseTable from '@/hooks/supabase-table'
import { Tables, TablesInsert } from '@/db_types'
import { QueryKeys } from '@/hooks/queryKeys'
import { Button } from 'react-aria-components'
import Notifications from '@/src/components/notifications'
import { isProduction } from '@/utils/environments'

interface SidebarLinkProps {
  full?: boolean
  closeSidebar: () => void
  kpisTables: Tables<'kpis_tables'>[]
}

const SidebarElement = ({
  full = false,
  closeSidebar,
  kpisTables
}: SidebarLinkProps) => {
  const router = useRouter()

  const {
    isAdmin,
    isSupervisor,
    hasFullView,
    isTeamManager,
    selectedOrganization,
    currentUser
  } = useOrgContext(state => ({
    isAdmin: state.getRole() === 'admin',
    isSupervisor: state.getRole() === 'supervisor',
    isTeamManager: state.isManager(),
    hasFullView: state.hasFullView(),
    currentUser: state.profile,
    selectedOrganization: state.selectedOrganization
  }))

  const replaceExceptionalRouting = (route: string) => {
    switch (route?.toUpperCase()) {
      case TABS.USER:
        return TABS.USERS.toLowerCase()
      case TABS.TEAM:
        return TABS.TEAMS.toLowerCase()
      default:
        return route
    }
  }

  const { items: userPlansSources } = useSupabaseTable<
    {
      id: number
      teams_profiles: {
        organization_id: number
        teams: {
          teams_plans: {
            plans: {
              source: string
            }
          }[]
        }
      }[]
    },
    TablesInsert<'profiles'>
  >({
    tableName: 'profiles',
    selectQuery:
      'id, teams_profiles!inner(organization_id, teams(teams_plans(plans(source))))',
    queryKeys: [QueryKeys.profiles.get],
    eqFilters: [
      {
        column: 'id',
        value: currentUser!.id
      },
      {
        column: 'teams_profiles.organization_id',
        value: selectedOrganization!.id
      }
    ]
  })
  const planSources = new Set(
    userPlansSources?.[0]?.teams_profiles?.flatMap(tp =>
      tp.teams?.teams_plans?.flatMap(tp => tp.plans.source)
    )
  )

  const deals_link: SidebarLink = {
    id: TABS.DEALS,
    label: t`Deals`,
    href: '/dashboard?page=deals',
    Icon: Rocket01
  }
  const activities_link: SidebarLink = {
    id: TABS.ACTIVITIES,
    label: t`Activities`,
    href: '/dashboard?page=activities',
    Icon: Activity
  }
  const invoices_link: SidebarLink = {
    id: TABS.INVOICES,
    label: t`Invoices`,
    href: '/dashboard?page=invoices',
    Icon: ReceiptCheck
  }

  const DATA_LINK_SUBLINKS = []
  if (selectedOrganization?.use_crm_tables) {
    if (!isAdmin && !isSupervisor) {
      if (
        planSources.has('deal') ||
        planSources.has('activity') ||
        planSources.has('invoice')
      ) {
        DATA_LINK_SUBLINKS.push(deals_link)
      }

      if (planSources.has('activity')) {
        DATA_LINK_SUBLINKS.push(activities_link)
      }
      if (planSources.has('invoice')) {
        DATA_LINK_SUBLINKS.push(invoices_link)
      }
    } else {
      DATA_LINK_SUBLINKS.push(deals_link, activities_link, invoices_link)
    }
  }

  if (kpisTables?.length > 0) {
    DATA_LINK_SUBLINKS.push(
      ...(kpisTables.map(table => ({
        id: table.id,
        label: table.label,
        Icon: Rows03,
        href: `/dashboard?page=kpis&table=${table.id}`
      })) ?? [])
    )
  }

  const TOP_SECTION: SidebarLink[] = [
    ...(hasFullView && !isAdmin && !isSupervisor
      ? [
          {
            id: TABS.DASHBOARD,
            href: '/dashboard',
            label: t`Dashboard`,
            Icon: BarChartSquare02
          },
          {
            id: TABS.MY_TEAM,
            label: t`My team`,
            href: '/dashboard?page=my_team',
            Icon: UserGroupIcon
          }
        ]
      : [
          {
            id: TABS.DASHBOARD,
            href: '/dashboard',
            label: t`Dashboard`,
            Icon: BarChartSquare02
          }
        ]),
    {
      id: TABS.COMPENSATIONS,
      label: t`Compensations`,
      href: '/dashboard?page=compensations',
      Icon: HeartHand
    },
    ...(isAdmin || isSupervisor || isTeamManager
      ? [
          {
            id: TABS.STATEMENTS,
            label: t`Statements`,
            href: '/dashboard?page=statements',
            Icon: File05
          }
        ]
      : [])
  ]

  const MIDDLE_SECTION: SidebarLink[] = [
    ...(isAdmin || isSupervisor || hasFullView
      ? [
          {
            id: TABS.USERS,
            label: t`Users & Teams`,
            href: '/dashboard?page=users',
            Icon: FaceSmile,
            subLinks: [
              {
                id: TABS.USERS,
                label: t`Users`,
                href: '/dashboard?page=users',
                Icon: User01
              },
              {
                id: TABS.TEAMS,
                label: t`Teams`,
                href: '/dashboard?page=teams',
                Icon: Users01
              }
            ]
          }
        ]
      : []),
    ...(isAdmin
      ? [
          {
            id: TABS.COMPENSATION_FRAMEWORK,
            label: t`Compensation framework`,
            href: '/dashboard?page=compensation_framework',
            Icon: Sliders02,
            subLinks: [
              {
                id: TABS.PLANS,
                label: t`Plans`,
                Icon: Dataflow01,
                href: '/dashboard?page=plans'
              },
              {
                id: TABS.TARGETS,
                label: t`Targets`,
                Icon: Target01,
                href: '/dashboard?page=targets'
              },
              {
                id: TABS.METRICS,
                label: t`Metrics`,
                href: '/dashboard?page=metrics',
                Icon: Ruler
              },
              ...(!isProduction()
                ? [
                    {
                      id: TABS.CHALLENGES,
                      label: t`Challenges`,
                      href: '/dashboard?page=challenges',
                      Icon: Trophy01
                    }
                  ]
                : []),
              {
                id: TABS.APPROVAL_FLOW,
                label: t`Approval flow`,
                Icon: AnnotationCheck,
                href: '/dashboard?page=approval_flow'
              },
              {
                id: TABS.TARGET_ACHIEVEMENTS,
                label: t`Target achievements`,
                Icon: Target04,
                href: '/dashboard?page=target_achievements'
              }
            ]
          }
        ]
      : hasFullView || isSupervisor
      ? [
          {
            id: TABS.TARGET_ACHIEVEMENTS,
            label: t`Target achievements`,
            Icon: Target04,
            href: '/dashboard?page=target_achievements'
          },
          ...(!isProduction()
            ? [
                {
                  id: TABS.CHALLENGES,
                  label: t`Challenges`,
                  href: '/dashboard?page=challenges',
                  Icon: Trophy01
                }
              ]
            : [])
        ]
      : !isProduction()
      ? [
          {
            id: TABS.CHALLENGES,
            label: t`Challenges`,
            href: '/dashboard?page=challenges',
            Icon: Trophy01
          }
        ]
      : []),
    ...(DATA_LINK_SUBLINKS.length > 0
      ? [
          {
            id: TABS.DATA,
            href:
              DATA_LINK_SUBLINKS.length === 0
                ? DATA_LINK_SUBLINKS[0].href
                : '/dashboard?page=deals',
            label: t`Data`,
            Icon: Data,
            subLinks: DATA_LINK_SUBLINKS
          }
        ]
      : []),
    ...(isAdmin
      ? [
          {
            id: TABS.INTEGRATIONS,
            label: t`Integrations`,
            href: '/dashboard?page=integrations',
            Icon: Link03
          }
        ]
      : [])
  ]

  const isActiveById = useCallback(
    (id: string | number): boolean =>
      router.query.page === 'kpis'
        ? id === Number(router.query.table)
        : router.query.page
        ? new RegExp(`(${id})`, 'gi').test(
            replaceExceptionalRouting(router.query.page as string)
          )
        : id === TABS.DASHBOARD,
    [router.query.page, router.query.table]
  )

  const defaultSubSidebar = useCallback(() => {
    return (
      TOP_SECTION.find(link =>
        link.subLinks?.some(subLink => isActiveById(subLink.id))
      )?.id || null
    )
  }, [JSON.stringify(TOP_SECTION), isActiveById])

  const isActive = (item: Omit<SidebarLink, 'id'> & { id: TABS | number }) => {
    return isActiveById(item.id)
  }

  const isChildrenActive = (item: SidebarLink) => {
    if (item.subLinks && item.subLinks.length > 0) {
      return item.subLinks.some(subItem => isActiveById(subItem.id))
    }
    return false
  }

  const [visibleSubSidebar, setVisibleSubSidebar] = useState<string | null>(
    defaultSubSidebar()
  )

  return (
    <div
      className={`${
        full
          ? 'w-full h-[calc(100vh-2rem)]'
          : 'w-[3.45rem] transition-all duration-500 ease-out hover:w-60 bottom-0 h-screen'
      } group fixed left-0 bg-neutral-0 md:bg-brand-900 flex flex-col pb-6 md:py-8 ${
        zIndexes.SIDEBAR
      }`}>
      {!full && (
        <Link
          shallow
          href='/dashboard'
          className={'ml-2 space-y-6 mb-6 w-full'}>
          <DolfinIcon color={'text-neutral-0 ml-1.5'} size={32} />
        </Link>
      )}

      <div
        className={`flex-grow flex flex-col ${
          full
            ? 'justify-start overflow-y-scroll pt-6 scrollbar-hide'
            : 'justify-between md:py-12 '
        }`}>
        <ul className={'w-full flex flex-col gap-2 px-0'}>
          {TOP_SECTION.map(item => (
            <SidebarItem
              item={item}
              closeSidebar={closeSidebar}
              isActive={isActive}
              isChildrenActive={isChildrenActive}
              visibleSubSidebar={visibleSubSidebar}
              setVisibleSubSidebar={setVisibleSubSidebar}
              full={full}
              key={item.id}
            />
          ))}
          <p
            className={
              'uppercase text-neutral-500 text-xs pl-6 md:pl-5 pt-8 pb-2 md:pb-0 pr-2 md:opacity-0 md:group-hover:opacity-100 overflow-hidden border-0'
            }>
            <Trans>Configuration</Trans>
          </p>
          <div
            className={
              'w-auto h-[1px] bg-neutral-500 mx-2 hidden md:flex md:group-hover:opacity-0'
            }></div>
          {MIDDLE_SECTION.map(item => (
            <SidebarItem
              item={item}
              closeSidebar={closeSidebar}
              isActive={isActive}
              isChildrenActive={isChildrenActive}
              visibleSubSidebar={visibleSubSidebar}
              setVisibleSubSidebar={setVisibleSubSidebar}
              full={full}
              key={item.id}
            />
          ))}
        </ul>

        <ul className={'w-full flex flex-col gap-2 px-0 pt-2'}>
          <SidebarItem
            item={{
              id: TABS.SETTINGS,
              label: t`Settings`,
              href: '/dashboard?page=settings',
              Icon: Settings02
            }}
            isActive={isActive}
            isChildrenActive={isChildrenActive}
            visibleSubSidebar={visibleSubSidebar}
            setVisibleSubSidebar={setVisibleSubSidebar}
            closeSidebar={closeSidebar}
            full={full}
          />
        </ul>
      </div>

      <Link
        shallow
        className={'px-6 md:px-0'}
        href={
          isAdmin || isSupervisor
            ? `/dashboard?page=user&id=${currentUser?.id}`
            : '/dashboard'
        }>
        <span className={'hidden md:block md:group-hover:hidden ml-2'}>
          <Avatar size={Sizes.SEMI_LARGE} />
        </span>

        <span
          className={`md:group-hover:block md:hidden text-neutral-700 md:text-neutral-100`}>
          <Avatar withDetails size={Sizes.SMALL} />
        </span>
      </Link>
    </div>
  )
}

const Sidebar = () => {
  const [showMobileSidebar, setShowMobileSidebar] = useState(false)

  const { items: kpisTables, isLoading: isLoadingKpis } = useSupabaseTable<
    Tables<'kpis_tables'>,
    TablesInsert<'kpis_tables'>
  >({
    tableName: 'kpis_tables',
    selectQuery: '*',
    queryKeys: [QueryKeys.kpis_tables.get]
  })

  if (isLoadingKpis) {
    return null
  }

  return (
    <>
      <div className={'hidden md:flex'}>
        <SidebarElement
          kpisTables={kpisTables}
          closeSidebar={() => setShowMobileSidebar(false)}
        />
      </div>

      {showMobileSidebar && (
        <div
          className={`fixed left-0 top-10 md:top-14 w-screen ${zIndexes.SIDEBAR}`}>
          <SidebarElement
            kpisTables={kpisTables}
            full
            closeSidebar={() => setShowMobileSidebar(false)}
          />
        </div>
      )}

      <div className='md:hidden fixed left-0 top-0 border-b bg-neutral-0 w-screen flex items-center justify-between py-3 px-6'>
        <Link shallow href='/dashboard'>
          <DolfinLogo className={'text-neutral-900 h-3'} />
        </Link>

        <div className={'flex gap-3'}>
          <Notifications />

          <Button
            className={'outline-none text-neutral-900'}
            onPress={() => setShowMobileSidebar(s => !s)}>
            {showMobileSidebar ? (
              <XMarkIcon width={18} />
            ) : (
              <Bars3Icon width={18} />
            )}
          </Button>
        </div>
      </div>
    </>
  )
}

export default Sidebar
