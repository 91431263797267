import '../scripts/wdyr'
import 'react-querybuilder/dist/query-builder.scss'
import '@/styles/global.css'
import '@/styles/loader.scss'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.css'

import { createPagesBrowserClient } from '@supabase/auth-helpers-nextjs'
import { SessionContextProvider } from '@supabase/auth-helpers-react'
import { ReactElement, ReactNode, useEffect, useState } from 'react'

import 'flowbite'
import Head from 'next/head'
import Script from 'next/script'
import type { AppProps } from 'next/app'
import type { NextPage } from 'next'
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { useRouter } from 'next/router'
import { QueryClient, QueryClientProvider } from 'react-query'
import ClientOnly from '@/utils/renderers'
import { Database } from '@/db_types'
import { Toaster } from '@/src/components'
import NextNProgress from 'nextjs-progressbar'
import LogRocket from 'logrocket'
import { ErrorBoundary, Provider as RollbarProvider } from '@rollbar/react'
import Intercom from '@intercom/messenger-js-sdk'
import { getOrgRole } from '@/utils/roles'

if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
  LogRocket.init('pjqufj/dolfin')
}

const queryClient = new QueryClient()

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const [supabaseClient] = useState(() =>
    createPagesBrowserClient<Database>({
      isSingleton: false
    })
  )

  const getLayout = Component.getLayout ?? ((page: ReactElement) => page)

  const router = useRouter()
  const locale =
    pageProps.locale ?? router.locale ?? router.defaultLocale ?? 'en'

  const isClient = typeof window !== 'undefined'

  if (!isClient && locale !== i18n.locale) {
    // there is single instance of i18n on the server
    i18n.loadAndActivate({ locale, messages: pageProps.translation })
  }
  if (isClient && !i18n.locale) {
    // first client render
    i18n.loadAndActivate({ locale, messages: pageProps.translation })
  }

  useEffect(() => {
    if (pageProps?.profile?.email) {
      Intercom({
        app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID!,
        region: 'eu',
        user_id: pageProps.profile.id,
        name: pageProps.profile.name,
        email: pageProps.profile.email,
        user_hash: pageProps.intercom_hash,
        'User Type': !!pageProps.selectedOrganization?.id
          ? getOrgRole(pageProps.profile, pageProps.selectedOrganization.id)
          : undefined
      })
    }
  }, [
    pageProps?.profile?.email,
    pageProps?.profile?.id,
    pageProps?.profile?.name,
    pageProps?.intercom_hash,
    pageProps?.selectedOrganization?.id
  ])

  useEffect(() => {
    const localeDidChange = locale !== i18n.locale
    if (localeDidChange) {
      i18n.loadAndActivate({ locale, messages: pageProps.translation })
    }
  }, [locale, pageProps.translation])

  useEffect(() => {
    if (pageProps.selectedOrganization) {
      const channelFileImport = supabaseClient.channel(
        `${pageProps.selectedOrganization.id}-data-import`
      )

      function messageReceived(payload: any) {
        console.log(payload)
      }

      channelFileImport
        .on('broadcast', { event: 'message' }, payload =>
          messageReceived(payload)
        )
        .subscribe()

      return () => {
        channelFileImport.unsubscribe()
      }
    }
  }, [pageProps.selectedOrganization, supabaseClient])

  const rollbarConfig = {
    accessToken: process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: process.env.NEXT_PUBLIC_VERCEL_ENV,
      client: {
        javascript: {
          code_version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
          source_map_enabled: true
        }
      }
    }
  }

  return (
    <>
      <Head>
        <meta charSet='UTF-8' />
        <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <title>Dolfin | Automating sales commissions</title>
        {/* Meta SEO */}
        <meta name='title' content='Dolfin - Calculate Commissions' />
        <meta
          name='description'
          content='Optimize your earnings and improve your bottom line with our powerful sales commission calculator. Say goodbye to manual calculations and guesswork - our streamlined SaaS solution makes it easy to accurately calculate commissions for improved revenue and stronger financial performance. Experience hassle-free commission tracking and unlock your full earning potential with our intuitive and precise commission calculation software'
        />
        <meta name='robots' content='index, follow' />
        <meta httpEquiv='Content-Type' content='text/html; charset=utf-8' />
        <meta name='language' content='English' />
        <meta name='author' content='Dolfin Team' />
        {/* Social media share */}
        <meta
          property='og:title'
          content='Dolfin - Streamline your commission calculations for a better bottom line.'
        />
        <meta property='og:site_name' content='Dolfin' />
        <meta property='og:url' content='https://dolfin.es/' />
        <meta
          property='og:description'
          content='Optimize your earnings and improve your bottom line with our powerful sales commission calculator. Say goodbye to manual calculations and guesswork - our streamlined SaaS solution makes it easy to accurately calculate commissions for improved revenue and stronger financial performance. Experience hassle-free commission tracking and unlock your full earning potential with our intuitive and precise commission calculation software'
        />
        <meta property='og:type' content='' />
        <meta
          property='og:image'
          content='/dolfin_logo_only_icon_blue_bg.png'
        />
        <meta name='theme-color' content='#030712' />

        {/* Favicon */}
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/apple-touch-icon.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='/favicon-32x32.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='/favicon-16x16.png'
        />
        <link rel='manifest' href='/site.webmanifest' />
      </Head>

      <NextNProgress />

      <RollbarProvider config={rollbarConfig}>
        <SessionContextProvider supabaseClient={supabaseClient}>
          <QueryClientProvider client={queryClient}>
            <I18nProvider i18n={i18n}>
              {getLayout(
                <ErrorBoundary>
                  <Component {...pageProps} />
                </ErrorBoundary>
              )}
              {/*<ReactQueryDevtools initialIsOpen={false} />*/}
              <div id={'modal-container'} />

              <ClientOnly>
                <Toaster />
              </ClientOnly>
            </I18nProvider>
          </QueryClientProvider>
        </SessionContextProvider>
      </RollbarProvider>

      {/* Microsoft Clarity */}
      {process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' && (
        <Script
          id='microsoft-clarity'
          strategy='afterInteractive'
          dangerouslySetInnerHTML={{
            __html: `
          (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", 'l8togon4jt');`
          }}
        />
      )}
    </>
  )
}

export default MyApp
