import type { Database, Enums, Json, Tables } from '@/db_types'
import { DateRangePickerValue } from '@tremor/react'
import { MessageDescriptor } from '@lingui/core'

export type Organization = Database['public']['Tables']['organizations']['Row']
export type Team = Database['public']['Tables']['teams']['Row']
export type TeamPlan = Database['public']['Tables']['teams_plans']['Row']
export type TeamPlanInsert =
  Database['public']['Tables']['teams_plans']['Insert']
export type TeamWithJoins = Tables<'teams'> & {
  profiles: Tables<'profiles'>[] | null
  plans: Tables<'plans'>[] | null
  tags: Tables<'tags'>[] | null
}
export type Plan = Database['public']['Tables']['plans']['Row']
export type PlanInsert = Database['public']['Tables']['plans']['Insert']
export type Profile = Database['public']['Tables']['profiles']['Row']
export type ProfileWithJoins = Profile & {
  organizations_profiles: (Tables<'organizations_profiles'> & {
    get_profile_status_field: Enums<'profile_status'>
  })[]
  profile_org_start_date: Tables<'profile_org_start_date'>[] | null
  teams:
    | (Tables<'teams'> & {
        plans:
          | (Tables<'plans'>[] & {
              targets:
                | (Tables<'targets'>[] &
                    (Tables<'target_profile_amount'>[] | null))
                | null
            })
          | null
        team_profiles: Tables<'teams_profiles'>[] | null
      })[]
    | null
  teams_profiles:
    | (Tables<'teams_profiles'> & { teams: Tables<'teams'> | null })[]
    | null
  payouts: Tables<'payouts'>[] | null
  statements: Tables<'statements'>[] | null
  deals: DealWithJoins[] | null
}
export type ProfileWithOrganizationProfileInfoAndTeams = Tables<'profiles'> & {
  organizations_profiles: Tables<'organizations_profiles'>[]
  teams: Tables<'teams'>[] | null
}

export type TeamMember = Tables<'profiles'> & {
  organizations_profiles: Tables<'organizations_profiles'> &
    { get_profile_status_field: Enums<'profile_status'> }[]
} & {
  invitations: Tables<'invitations'>
} & Pick<Tables<'teams_profiles'>, 'role' | 'full_view_access' | 'team_id'>

export type TeamWithMembers = Tables<'teams'> & {
  teams_profiles: (Tables<'teams_profiles'> & {
    profiles: TeamMember
  })[]
}

export type TeamProfile = Database['public']['Tables']['teams_profiles']['Row']
export type TeamProfileInsert =
  Database['public']['Tables']['teams_profiles']['Insert']
export type ProfileInsert = Database['public']['Tables']['profiles']['Insert']
export type ProfileUpdate = Database['public']['Tables']['profiles']['Update']
export type EmailLeadInsert =
  Database['public']['Tables']['email-leads']['Insert']
export type TeamInsert = Database['public']['Tables']['teams']['Insert']
export type Template = Database['public']['Tables']['template']['Row']
export type TemplateWithJoins = Template & {
  tags: Tables<'tags'>[] | null
}
export type TemplateInsert = Database['public']['Tables']['template']['Insert']
export type Invitation = Database['public']['Tables']['invitations']['Row']
export type InvitationInsert =
  Database['public']['Tables']['invitations']['Insert']
export type OrganizationsProfiles =
  Database['public']['Tables']['organizations_profiles']['Row']
export type OrganizationsProfilesInsert =
  Database['public']['Tables']['organizations_profiles']['Insert']
export type Tags = Database['public']['Tables']['tags']['Row']
export type TagsInsert = Database['public']['Tables']['tags']['Insert']
export type Target = Database['public']['Tables']['targets']['Row']
export type TargetWithJoins = Tables<'targets'> & {
  plans: Tables<'plans'>[] | null
} & {
  target_amount: Tables<'target_profile_amount'>[] | null
  target_achievement: Tables<'target_achievement'>[] | null
  target_period_amount: Tables<'target_period_amount'>[]
}
export type TargetsInsert = Database['public']['Tables']['targets']['Insert']
export type DealWithJoins = Database['public']['Tables']['deals']['Row'] & {
  profiles: Tables<'profiles'> | null
  payouts: Tables<'payouts'>[] | null
  invoices: Tables<'invoices'>[] | null
  activities: ActivitiesWithProfiles[] | null
}
export type Deals = Database['public']['Tables']['deals']['Row']

export type Execution = Database['public']['Tables']['executions']['Row']

export type Payout = Database['public']['Tables']['payouts']['Row']
export type PayoutWithJoins = Tables<'payouts'> & {
  statements: Tables<'statements'> | null
  executions: Tables<'executions'> | null
  issues: Tables<'issues'>[] | null
  deals: DealWithJoins | null
  profiles:
    | (Tables<'profiles'> & {
        teams_profiles:
          | (Tables<'teams_profiles'> & { teams: Tables<'teams'> | null })[]
          | null
      })
    | null
  plans: Tables<'plans'> | null
  compensation_rules:
    | (Tables<'compensation_rules'> & {
        kpis_tables: Tables<'kpis_tables'> | null
      })
    | null
  targets: Tables<'targets'> | null
  challenges: Tables<'challenges'> | null
  kpis: KpisWithTables | null
  payout_validations:
    | (Tables<'payout_validations'> & { profiles: Tables<'profiles'> })[]
    | null
}

export type StatementsDataType = Tables<'statements'> & {
  issues?: Tables<'issues'>[] | null
  payouts: { sum: number }[]
  statement_approvals:
    | (Tables<'payout_validations'> & { profiles: Tables<'profiles'> })[]
    | null
}

export type HistoricStatementsType = Tables<'statements'> & {
  payouts: { sum: number }[]
}

export type MetricsWithJoins = Tables<'metrics'> & {
  kpis_tables: Tables<'kpis_tables'> | null
  plans:
    | (Tables<'plans'> & {
        kpis_tables: Pick<Tables<'kpis_tables'>, 'id' | 'label'>
      })
    | null
  targets: Tables<'targets'> | null
}

export type TargetsWithMetricsAndTeams = Tables<'targets'> & {
  metrics: Tables<'metrics'> | null
  teams: (Tables<'teams'> & {
    teams_profiles: {
      profiles: Pick<Tables<'profiles'>, 'id' | 'name' | 'email' | 'img_url'>
    }[]
  })[]
}

export type PayoutInsert = Database['public']['Tables']['payouts']['Insert']

export type InvoicesWithDeals = Tables<'invoices'> & {
  deals: Tables<'deals'> | null
}

export type KpisWithTables = Tables<'kpis'> & {
  kpis_tables: Tables<'kpis_tables'> | null
}

export type TargetAchievementInsert =
  Database['public']['Tables']['target_achievement']['Insert']

export type ProfileOrgVariableSalary =
  Database['public']['Tables']['profile_org_variable_salary']['Row']
export type ProfileOrgVariableSalaryInsert =
  Database['public']['Tables']['profile_org_variable_salary']['Insert']

export type ActivitiesWithDeals = Tables<'activities'> & {
  deals: Tables<'deals'> | null
}
export type ActivitiesWithProfiles = Tables<'activities'> & {
  profiles: ProfileWithOrganizationProfileInfoAndTeams | null
}

export type PlanWithJoins = Tables<'plans'> & {
  teams:
    | (Tables<'teams'> & {
        profiles: Tables<'profiles'>[] | null
      })[]
    | null
  targets?: Tables<'targets'>[] | null
  compensation_rules?:
    | (Tables<'compensation_rules'> &
        {
          kpis_tables?: Tables<'kpis_tables'> | null
          compensation_rule_drafts?: Tables<'compensation_rule_drafts'> | null
        }[])
    | null
}

export type CompRuleWithPlanTeamsAndDrafts = Tables<'compensation_rules'> & {
  plans: Tables<'plans'> & {
    teams: (Tables<'teams'> & { profiles: Tables<'profiles'> })[]
  }
  compensation_rule_drafts?: Tables<'compensation_rule_drafts'>
}

export type DefinedPartial<T> = {
  [P in keyof T]?: Exclude<T[P], undefined>
}

export type TeamWithProfiles = Tables<'teams'> & {
  profiles: ProfileWithJoins[]
}

export type DBFunctions = Database['public']['Functions']

export type NotificationInsert =
  Database['public']['Tables']['notifications']['Insert']

export type CommentMentionWithJoins = Tables<'comments_mentions'> & {
  profiles: Tables<'profiles'> | null
}

export type CommentWithJoins = Tables<'comments'> & {
  profiles: Tables<'profiles'> | null
  comments_mentions: CommentMentionWithJoins[]
}

export type NotificationWithJoins = Tables<'notifications'> & {
  profiles: Tables<'profiles'> | null
  notifications_status: Tables<'notifications_status'>[] | null
  comments: CommentWithJoins[] | null
}

export enum TABS {
  DASHBOARD = 'DASHBOARD',
  MY_TEAM = 'MY_TEAM',
  DEALS = 'DEALS',
  DATA = 'DATA',
  USERS = 'USERS',
  USER = 'USER',
  TEAMS = 'TEAMS',
  TEAM = 'TEAM',
  PLANS = 'PLANS',
  COMPENSATION_FRAMEWORK = 'COMPENSATION_FRAMEWORK',
  COMPENSATIONS = 'COMPENSATIONS',
  CHALLENGES = 'CHALLENGES',
  STATEMENTS = 'STATEMENTS',
  ACTIVITIES = 'ACTIVITIES',
  INVOICES = 'INVOICES',
  INTEGRATIONS = 'INTEGRATIONS',
  SETTINGS = 'SETTINGS',
  TARGETS = 'TARGETS',
  APPROVAL_FLOW = 'APPROVAL_FLOW',
  METRICS = 'METRICS',
  TARGET_ACHIEVEMENTS = 'TARGET_ACHIEVEMENTS'
}

export type Timeframe = DateRangePickerValue

export type GetPayoutsDataReturn =
  Database['public']['Functions']['get_payouts_data_with_change']['Returns']

export type SupabaseTableHookQueryOperators = {
  range?: {
    from?: number
    to?: number
  }
  ilikeFilters?: Array<{
    column: string
    value: string
  }>
  inFilters?: {
    column: string
    values: (string | number)[]
  }[]
  containsFilters?: {
    column: string
    values: (string | number)[]
  }[]
  filters?:
    | {
        column: string
        value: string | number | boolean | null
        operator: string
      }
    | {
        column: string
        value: string | number | boolean | null
        operator: string
      }[]
  orFilter?:
    | string
    | [
        string,
        {
          foreignTable: string
        }
      ]
  eqFilters?: Array<{
    column: string
    value: string | number | boolean
  }>
  neqFilters?: Array<{
    column: string
    value: string | number | boolean | null
  }>
  notFilter?: [string, string, string | string[]]
  gtFilters?: Array<{
    column: string
    value: string | number
    orEqual: boolean
  }>
  ltFilters?: Array<{
    column: string
    value: string | number
    orEqual: boolean
  }>
  order?: Array<{
    column: string
    options?: {
      ascending?: boolean
      nullsFirst?: boolean
      referencedTable?: string
    }
  }>
  isFilters?: Array<{
    column: string
    value: boolean | null
    negate?: boolean
  }>
  textSearch?: {
    column: string
    query: string
    options: {
      config?: string | undefined
      type?: 'plain' | 'phrase' | 'websearch' | undefined
    }
  }
  limit?: number
  timeRange?: Timeframe
}

export type SupabaseTableHook<T> = {
  enabled?: boolean
  queryKeys?: any[]
  onSuccess?: (items: { items: T[]; count: number | null }) => void
  onError?: (error: Error) => void
  setLoading?: (b: boolean) => void
  afterQuery?: (items: { items: T[]; count: number | null }) => void
  afterInsert?: (items: T[]) => void | Promise<void>
  afterUpsert?: (items: T[]) => void | Promise<void>
  afterUpdate?: (item: T) => void | Promise<void>
  afterUpdateItems?: (items: T[]) => void | Promise<void>
  afterGetById?: (item: T) => void | Promise<void>
  afterDelete?: (
    id:
      | (number | string | T)
      | (string | number | T)[]
      | { [key: string]: unknown }
  ) => void | Promise<void>
  countItems?: boolean
  head?: boolean
  notify?: boolean
  notifyError?: boolean
  upsertOptions?: {
    onConflict?: string
    ignoreDuplicates?: boolean
    count?: 'exact' | 'planned' | 'estimated'
  }
  skipOrgFilter?: boolean
  refetchOnWindowFocus?: boolean
  refetchOnMount?: boolean
} & SupabaseTableHookQueryOperators

export type TemplateVariable = {
  type: 'string' | 'number'
  label: string
}

type JsonOrNull = Json | null

export type MapJsonToRecord<T> = {
  [P in keyof T]: T[P] extends JsonOrNull
    ? Record<string, any> | null
    : T[P] extends Json
    ? Record<string, any>
    : T[P]
}

export type MapToOptional<T> = {
  [P in keyof T]?: T[P]
}

export type DBFunctionReturns<
  FunctionName extends keyof Database['public']['Functions']
> = FunctionName extends keyof Database['public']['Functions']
  ? Database['public']['Functions'][FunctionName]['Returns']
  : never

export type Tag = {
  id?: string
  type: string
  label: MessageDescriptor
  color?: string
  parsedValue?: string | number
  definition: MessageDescriptor
  matcher: { test: (text: string) => boolean }
  options?:
    | Record<
        string,
        {
          label: MessageDescriptor
          description: MessageDescriptor
        }
      >
    | string[]
  source?: Enums<'plan_source'>
}

export type GridTable = keyof Pick<
  Database['public']['Tables'],
  | 'deals'
  | 'kpis'
  | 'profiles'
  | 'invoices'
  | 'activities'
  | 'metrics'
  | 'targets'
  | 'statements'
  | 'payouts'
>

export type DatabaseTableName = keyof Database['public']['Tables']

type PickMatching<T, V> = { [K in keyof T as T[K] extends V ? K : never]: T[K] }

export type ExtractMethodsFromClass<T> = PickMatching<T, Function>

export enum TargetAchievementStatuses {
  UPCOMING = 'upcoming',
  ONGOING_FOLLOW_PACE = 'ongoing-follow-pace',
  ONGOING_NOT_FOLLOW_PACE = 'ongoing-not-follow-pace',
  FINISHED_ACHIEVED = 'finished-achieved',
  FINISHED_NOT_ACHIEVED = 'finished-not-achieved'
}
